














import Vue from 'vue';
import { IJournal } from '@/scripts/store/modules/tools/journals/types';
import journalForm from '@/views/tools/journal/components/journal-form.vue';

export default Vue.extend({
  name: 'edit-journal',
  components: {
    journalForm,
  },
  props: {
    journal: {
      type: Promise,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadedJournal: null as IJournal | null,
    };
  },
  computed: {
    storeJournals(): IJournal {
      return this.$store.getters['journals/journals'];
    },
    hasJournals(): Boolean {
      return !!this.storeJournals;
    },
  },
  mounted() {
    this.journal.then(res => {
      this.loadedJournal = res as IJournal;
    });
  },
  methods: {
    goToJournalList() {
      this.$router.replace({ name: 'journals-list' });
    },
  },
});
