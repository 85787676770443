var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadedJournal
    ? _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("h2", { staticClass: "starling-h2 StarlingPrimary2--text" }, [
              _vm._v(_vm._s(_vm.$t("app.tools.journal.page_title")))
            ]),
            _c("h3", { staticClass: "starling-h3 StarlingPrimary2--text" }, [
              _vm._v(_vm._s(_vm.$t("app.tools.journal.page_sub_title")))
            ]),
            _c("p", { staticClass: "starling-text" }, [
              _vm._v(_vm._s(_vm.$t("app.tools.journal.page_instruction")))
            ])
          ]),
          _c(
            "v-flex",
            {
              staticClass: "journal-edit-card",
              attrs: { xs12: "", lg9: "", "text-left": "" }
            },
            [
              _c("journal-form", {
                attrs: { journal: _vm.loadedJournal, cancellable: "" },
                on: { update: _vm.goToJournalList, cancel: _vm.goToJournalList }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }